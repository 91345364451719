'use client';

import { NoTournamentData } from '@spikemark/ui/components/placeholder/no-tournament-data';
import { isLiveTournament } from '@/util/tournament';
import { ElasticRoundAnalyticsWithAveragePoints, Tournament } from '@spikemark/rest-api';
import { MascotWithName } from '@spikemark/ui/components/mascot-with-name';
import { PlayerLink, TeamLink } from '@spikemark/ui/components/navigation/entity-links';
import { PositionDeltaDisplay } from '@spikemark/ui/components/position-delta-display';
import { ScoreToPar } from '@spikemark/ui/components/score-to-par';
import { Table } from '@spikemark/ui/components/table/table';
import { MatchplayLeaderboardItem } from '@spikemark/ui/types/leaderboard';
import { displayTeamName, displayUsername, formatThru } from '@spikemark/ui/utils/format';
import { scoreFormat } from '@spikemark/ui/utils/score';
import { FC } from 'react';
export type PreviewLeaderboardProps = {
  type: 'Team' | 'Player';
  tournamentId: string;
};
export const PreviewStrokeplayLeaderboardGrid: FC<{
  data: ElasticRoundAnalyticsWithAveragePoints[];
  tournament: Tournament;
  type: PreviewLeaderboardProps['type'];
}> = ({
  data,
  type,
  tournament
}) => {
  return <Table<ElasticRoundAnalyticsWithAveragePoints> mode="auto" compact className="w-full text-xs 2xl:text-sm" columns={[{
    key: 'currentRank',
    cellClassName: 'numeric'
  }, {
    header: '+/-',
    key: 'rankDelta',
    cellClassName: 'numeric',
    cellRenderer: ({
      rowData
    }) => <PositionDeltaDisplay change={rowData.rankDelta ?? 0} />
  }, {
    header: 'Team',
    cellClassName: 'max-w-[150px]',
    cellRenderer: ({
      rowData
    }) => type === 'Team' ? <TeamLink schoolId={rowData.schoolId} suppressChevron>
                <MascotWithName alt={rowData.schoolName} path={rowData.schoolLogo} variant="small" displayMode="team" className="whitespace-break-spaces">
                  {displayTeamName(rowData.schoolName, rowData.teamLabel)}
                </MascotWithName>
              </TeamLink> : <PlayerLink playerId={rowData.playerId} suppressChevron>
                <MascotWithName alt={rowData.schoolName} path={rowData.schoolLogo} variant="small" displayMode="team" className="whitespace-break-spaces">
                  {displayUsername(rowData.playerName, rowData.playerType)}
                </MascotWithName>
              </PlayerLink>,
    width: '90%'
  }, {
    header: 'Total',
    cellClassName: 'numeric',
    cellRenderer: ({
      rowData
    }) => <ScoreToPar score={rowData.totalScore} />
  }, {
    header: 'Thru',
    cellClassName: 'numeric',
    cellRenderer: ({
      rowData
    }) => formatThru(rowData.holeThrough ?? 18)
  }, {
    header: 'Today',
    cellClassName: 'numeric',
    cellRenderer: ({
      rowData
    }) => scoreFormat(rowData.scores?.[rowData.scores?.length - 1] ?? 0)
  }]} data={data?.slice(0, 7) ?? []} emptyMessage={<NoTournamentData isLiveScored={isLiveTournament(tournament)} />} rowKey={({
    rowData
  }) => type === 'Player' ? rowData.playerId! : `${rowData.schoolId}-${rowData.teamLabel ?? ''}`} headerRowClassName="shadow-sm light:bg-white" rowClassName="h-[3rem] align-middle border-b border-gray-200" data-sentry-element="Table" data-sentry-component="PreviewStrokeplayLeaderboardGrid" data-sentry-source-file="preview-leaderboard-grid.tsx" />;
};
export const PreviewMatchplayLeaderboardGrid: FC<{
  data?: MatchplayLeaderboardItem[];
  tournament: Tournament;
}> = ({
  data,
  tournament
}) => {
  if (!data || data?.length === 0) {
    return <NoTournamentData isLiveScored={isLiveTournament(tournament)} />;
  }
  return <Table<MatchplayLeaderboardItem> mode="auto" compact className="w-full text-xs 2xl:text-sm" columns={[{
    key: 'position',
    cellClassName: 'numeric'
  }, {
    header: 'Team',
    cellClassName: 'max-w-[150px]',
    cellRenderer: ({
      rowData
    }) => <TeamLink schoolId={rowData.id} suppressChevron>
              <MascotWithName alt={rowData.displayName} path={rowData.schoolLogo} variant="small" displayMode="team" className="whitespace-break-spaces">
                {rowData.displayName}
              </MascotWithName>
            </TeamLink>,
    width: '85%'
  }, {
    header: 'W',
    cellClassName: 'numeric',
    cellRenderer: ({
      rowData
    }) => rowData.record.wins,
    width: '5%'
  }, {
    header: 'L',
    cellClassName: 'numeric',
    cellRenderer: ({
      rowData
    }) => rowData.record.losses,
    width: '5%'
  }, {
    header: 'T',
    cellClassName: 'numeric',
    cellRenderer: ({
      rowData
    }) => rowData.record.draws,
    width: '5%'
  }]} data={data.sort((a, b) => (a?.position || 0) - (b?.position || 0)).slice(0, 7)} emptyMessage="No leaderboard data available" rowKey={({
    rowData
  }) => rowData.displayName} headerRowClassName="shadow-sm light:bg-white" rowClassName="h-[3rem] align-middle border-b border-gray-200" data-sentry-element="Table" data-sentry-component="PreviewMatchplayLeaderboardGrid" data-sentry-source-file="preview-leaderboard-grid.tsx" />;
};