import { ElasticCourseData } from '@spikemark/rest-api';
import keyBy from 'lodash/keyBy';

export const scoreFormat = (score: number) => {
  if (score === 0) {
    return 'E';
  } else if (score === -1000) {
    return `-`;
  } else if (score > 9000) {
    return 'N/A';
  } else if (score > 0) {
    return `+${score}`;
  } else {
    return score;
  }
};

export const createCourseInfoMap = (courses?: ElasticCourseData[]) => {
  return keyBy(courses, (course) => [course.roundId, course.courseLabel].join('-'));
};
