import {
  Tournament,
  TournamentEventTypeEnum,
  TournamentRequestTournamentIntegrationIdEnum,
} from '@spikemark/rest-api';

export function getTournamentDefaultUrl(tournament: Tournament): string {
  const { eventType, tournamentId, hasResults } = tournament;

  const isPrePlay = !hasResults;
  const isMatchPlay =
    eventType === TournamentEventTypeEnum.MatchPlay ||
    eventType === TournamentEventTypeEnum.MedalMatchPlay;

  return `/tournaments/${tournamentId}/scoring/${
    isPrePlay ? 'participants' : isMatchPlay ? 'round' : 'team'
  }`;
}

export function isLiveTournament(
  tournament?: {
    tournamentIntegrationId?: TournamentRequestTournamentIntegrationIdEnum | string;
  } | null
): boolean {
  return Boolean(
    tournament &&
      'tournamentIntegrationId' in tournament &&
      tournament.tournamentIntegrationId ===
        TournamentRequestTournamentIntegrationIdEnum.Scoreboardlive
  );
}

export function isActiveLiveTournament(
  tournament?: {
    tournamentIntegrationId?: TournamentRequestTournamentIntegrationIdEnum | string;
    isComplete?: boolean;
  } | null
): boolean {
  return Boolean(isLiveTournament(tournament) && !tournament?.isComplete);
}
