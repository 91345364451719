'use client';

import { PreviewLeaderboardProps } from '@/components/tables/leaderboard/preview/preview-leaderboard';
import { Tournament } from '@spikemark/rest-api';
import { TabsContent } from '@spikemark/ui/components/ui/tabs';
import { FC } from 'react';
import { useInView } from 'react-intersection-observer';
type TournamentPreviewBodyProps = {
  tournament: Tournament;
  leaderboardComponent: FC<PreviewLeaderboardProps>;
};
export const TournamentPreviewBody: FC<TournamentPreviewBodyProps> = ({
  leaderboardComponent: LeaderboardComponent,
  tournament
}) => {
  const {
    ref,
    inView
  } = useInView({
    triggerOnce: true
  });
  return <div ref={ref} data-sentry-component="TournamentPreviewBody" data-sentry-source-file="tournament-preview-body.tsx">
      <TabsContent value="Player" data-sentry-element="TabsContent" data-sentry-source-file="tournament-preview-body.tsx">
        <LeaderboardComponent type="Player" isInView={inView} tournament={tournament} data-sentry-element="LeaderboardComponent" data-sentry-source-file="tournament-preview-body.tsx" />
      </TabsContent>
      <TabsContent value="Team" data-sentry-element="TabsContent" data-sentry-source-file="tournament-preview-body.tsx">
        <LeaderboardComponent type={'Team'} isInView={inView} tournament={tournament} data-sentry-element="LeaderboardComponent" data-sentry-source-file="tournament-preview-body.tsx" />
      </TabsContent>
    </div>;
};