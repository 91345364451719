import classNames from 'classnames';
import { FC } from 'react';
export const ScoreToPar: FC<{
  size?: 'default' | 'large';
  score?: number;
}> = ({
  size = 'default',
  score
}) => {
  if (typeof score === 'number') {
    const className = classNames('text-center inline-flex justify-center rounded-full text-white print:w-auto print:h-auto print:bg-transparent print:text-primary', {
      'w-8 h-8 text-xs': size === 'default',
      'w-10 h-10 text-base': size === 'large',
      'bg-bogey-blue': score === 0,
      'bg-birdie-red': score < 0,
      'bg-over-par-gray': score > 0
    });
    if (score > 10000) {
      return <div className={className}>
          <div className="self-center">N/A</div>
        </div>;
    }
    return <div className={className}>
        <div className="self-center">{score === 0 ? 'E' : `${score > 0 ? '+' : ''}${score}`}</div>
      </div>;
  } else {
    return '-';
  }
};