'use client';

import { BaseLink as Link } from './navigation/entity-links';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { Button, ButtonProps } from './ui/button';
import classNames from 'classnames';
import { Dot } from 'lucide-react';
type CardProps = PropsWithChildren<{
  title?: string;
  className?: string;
  buttonLink?: string;
  buttonText?: string;
  buttonSize?: ButtonProps['size'];
  buttonVariant?: ButtonProps['variant'];
}>;
export const Card: FC<CardProps> = ({
  children,
  className,
  buttonLink,
  buttonText,
  buttonSize = 'xl',
  buttonVariant
}) => <div className={classNames('relative w-full mx-auto rounded-xl overflow-clip flex flex-col justify-between shadow bg-white dark:bg-primary dark:bg-gradient-to-b dark:from-primary dark:to-primary-dark border border-zinc-300 dark:border-0', className)} data-sentry-component="Card" data-sentry-source-file="card.tsx">
    <div className="flex-1 flex flex-col">{children}</div>
    {buttonLink && buttonText && <div className="px-8 py-7 flex justify-center">
        <Button className="px-16" variant={buttonVariant} size={buttonSize} asChild>
          <Link className="mt-2 lg:mt-4 whitespace-nowrap" href={buttonLink}>
            {buttonText}
          </Link>
        </Button>
      </div>}
  </div>;
type CardHeaderProps = PropsWithChildren<{
  title: ReactNode;
  subTitle?: ReactNode;
  badge?: ReactNode;
  description?: ReactNode;
  titleLink?: string;
  variant?: 'default' | 'compact';
}>;
export const CardHeader: FC<CardHeaderProps> = ({
  title,
  children,
  description,
  subTitle,
  badge,
  titleLink,
  variant = 'default'
}) => {
  const wrapperClass = classNames('flex bg-gradient-to-b from-primary to-primary-dark dark:from-transparent dark:to-transparent', {
    'p-6 space-x-2': variant === 'default',
    'p-2': variant === 'compact'
  });
  return <div className={wrapperClass} data-sentry-component="CardHeader" data-sentry-source-file="card.tsx">
      <div className="block lg:flex items-end justify-between flex-1">
        <div className="flex-1 flex-col">
          {badge}
          <h3 className="text-sm lg:text-base text-white leading-snug break-word line-clamp-1 pt-3">
            {titleLink ? <Link href={titleLink}>{title}</Link> : title}
          </h3>
          {subTitle || description ? <div className="flex space-x-1 text-coolgray-lightest text-xs items-center">
              <p>{subTitle}</p>
              {description ? <>
                  <Dot />
                  <p>{description}</p>
                </> : null}
            </div> : null}
        </div>
      </div>
      {children}
    </div>;
};